export default function addNoDataLayer(mapInstance) {
  const layer = "noData-layer";
  const noDataSource = {
    type: "vector",
    url: "mapbox://aashary.4l7f9d2j",
  };

  // Add the new tileset layer
  const noDataLayer = {
    id: layer,
    type: "fill",
    source: noDataSource,
    "source-layer": "gadm_v410_activity_filtered-9rpnqj",
    paint: {
      "fill-color": "#5a5a5a", // dark grey
      "fill-opacity": 0.3,
    },
  };

  mapInstance.addSource("noData-src", noDataSource);
  mapInstance.addLayer(noDataLayer);

  mapInstance.on("click", layer, async function (e) {
    // this will prevent user from clicking through the layer to admin 1 or 2
    e.originalEvent.cancelMapInteractivityOnEventClick = true;
  });
}
