import EventsPopUp from "../components/PopUp/components/Events";
import { getSpecialEvents } from "../../../endpoints";
import { layers, constants } from "@ctrees-products/web-mapbox-interactivity";

export default function loadEvents(map, openModal) {
  const source = constants.source;
  if (map.getSource(source)) {
    return;
  }
  getSpecialEvents().then((data) => {
    const yellow = "#DFF841";
    const type = "circle";
    const source = constants.source;
    const outer = constants.outer;
    const inner = constants.inner;
    const clickable = constants.clickable;

    // Add a GeoJSON source with your point data
    map.addSource(source, data);

    // Add a layer for the outer border
    map.addLayer({
      id: outer,
      type,
      source,
      paint: {
        "circle-radius": 10,
        "circle-color": "transparent",
        "circle-stroke-width": 1,
        "circle-stroke-color": yellow,
      },
    });

    // Add a layer for the inner solid circle
    map.addLayer({
      id: inner,
      type,
      source,
      paint: {
        "circle-radius": 8,
        "circle-color": yellow,
      },
    });

    // Add a layer for the transparent clickable area
    map.addLayer({
      id: clickable,
      type,
      source,
      paint: {
        "circle-radius": 12,
        "circle-color": "transparent",
      },
    });

    // Set the order of layers
    layers.setLayerOrder(map);

    map.on("click", clickable, (e) => {
      e.originalEvent.stopPropagation();

      const features = e.features;
      if (!features.length) return;

      e.originalEvent.stopPropagation();

      const feature = features[0];
      const properties = feature.properties;
      const subtitle = `${properties.start_date} - ${properties.end_date}`;
      const url = `${process.env.REACT_APP_REDIRECT_LOGIN}/news/${properties.newsfeed_uri}`;

      // Zoom in to the feature's location
      map.flyTo({
        center: feature.geometry.coordinates,
        zoom: 10, 
        essential: true, // animation will not be interrupted
      });
      
      openModal(
        "",
        "",
        <EventsPopUp
          title={properties.newsfeed_title}
          desc={properties.newsfeed_summary}
          subtitle={subtitle}
          articleUrl={url}
          state={properties.newsfeed_state}
        />,
        false
      );
    });

    // Change the cursor to a pointer when hovering over the clickable circle
    map.on("mouseenter", clickable, () => {
      map.getCanvas().style.cursor = "pointer";
    });

    // Change the cursor back when leaving the clickable circle
    map.on("mouseleave", clickable, () => {
      map.getCanvas().style.cursor = "";
    });
  });
}
