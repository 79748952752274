import { useEffect, useState } from "react";
import { Map } from "@ctrees-products/web-mapbox-interactivity";
import { mapStyle } from "../../constants";
import { useCurrentSelection, useCurrentMap } from "../../contexts";
import Coordinates from "./components/Coordinates";
import Scale from "./components/Scale";

const Mapbox = () => {
  const { currentSelection } = useCurrentSelection();
  const { currentMap } = useCurrentMap();
  const [lngLat, setLngLat] = useState({ lng: 0, lat: 0 });

  useEffect(() => {
    currentMap.mapRef.current?.on("mousemove", (e) => {
      setLngLat(e.lngLat);
    });
  }, [currentMap.mapRef.current]);

  return (
    <div className="relative">
      <Map
        currentSelection={currentSelection}
        currentMap={currentMap}
        mapStyle={mapStyle}
        className="h-screen w-full -mt-20"
      />
      {currentMap.mapRef?.current && (
        <>
          <Scale />
          <Coordinates />
        </>
      )}
    </div>
  );
};

export default Mapbox;
