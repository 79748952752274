import { useEffect } from "react";
import * as styles from "./styles";
import * as components from "../components";
import * as dropdown from "../components/Dropdown";
import { maps } from "@ctrees-products/web-mapbox-interactivity";
import { loadEvents, addNoDataLayer } from "../components/Mapbox/helpers";
import { QuestionMarkIcon, LayersIcon } from "../components/Icons";
import * as constants from "../constants";
import * as context from "../contexts";

export default function MapBase() {
  const { currentSelection } = context.useCurrentSelection();
  const { currentMap } = context.useCurrentMap();
  const { currentModal } = context.useCurrentModal();

  //-----------------------------------------//
  //--- INITIALIZE MAP LOAD FUNCTIONALITY ---//
  //-----------------------------------------//
  useEffect(() => {
    const { mapStyle } = constants;
    
    // initialize map and store reference
    currentMap.mapRef.current = maps.initMap(mapStyle);
    let currentMapRef = currentMap.mapRef.current;

    // keep on load functionality here to have more control over the load event
    currentMapRef.on("load", () => {
      currentMap.setMapLoaded(true);
      maps.onMapLoad(
        currentMapRef,
        mapStyle.gidOnLoad,
        currentSelection.setSelection,
        mapStyle.tileserverOnLoad,
        mapStyle.minZoom,
        mapStyle.maxZoom,
        mapStyle.borderColor
      );

      loadEvents(currentMapRef, currentModal.openModal);
      addNoDataLayer(currentMapRef);
    });

    return () => {
      if (currentMapRef) {
        if (currentMapRef.off) {
          currentMapRef.off("load");
        }
        if (
          currentMap.scaleControlRef.current &&
          currentMap.mapRef.removeControl
        ) {
          currentMapRef.removeControl(currentMap.scaleControlRef.current);
        }
        currentMapRef.remove();
        currentMapRef = null; // Clear the reference to avoid any unintended access
      }
    };
  }, []);

  //------------------------//
  //--- MODAL PROPERTIES ---//
  //------------------------//
  const modalProps = {
    title: currentModal.modalTitle,
    desc: currentModal.modalDesc,
    isOpen: currentModal.modalIsOpen,
    onRequestClose: currentModal.closeModal,
  };

  //-------------------------//
  //--- BUTTON PROPERTIES ---//
  //-------------------------//
  const buttonProps = {
    onClick: () => {
      currentModal.openModal(
        constants.questionMarkModal.title,
        "",
        constants.questionMarkModal.contents
      );
    },
    backgroundColor: "whiteButton",
    textColor: "black",
    roundedSize: "rounded-md",
  };

  //------------------------//
  //--- LAYER PROPERTIES ---//
  //------------------------//
  const layerDropdownProps = {
    onClick: null, // the dropdown component will handle the action of the button
    backgroundColor: "whiteButton",
    textColor: "black",
    roundedSize: "rounded-md",
    className: "dropdownTitleElement",
  };

  const dropdownProps = {
    icon: (
      <components.Button {...layerDropdownProps} width={10}>
        <LayersIcon className="dropdownTitleElement" />
      </components.Button>
    ),
    isOpen: currentSelection.layerDropdownIsOpen,
    setIsOpen: () =>
      currentSelection.setLayerDropdownIsOpen(
        !currentSelection.layerDropdownIsOpen
      ),
  };

  const dropdownContentsProps = {
    options: constants.layerOptions,
    isOpen: currentSelection.layerDropdownIsOpen,
    selected: currentSelection.selectedLayer,
    handleItemClick: currentSelection.changeLayer,
  };

  //-----------------------//
  //--- RENDER MAP BASE ---//
  //-----------------------//
  return (
    <>
      <div className={styles.screen}>
        <components.Onboarding />

        <div className={styles.searchAndLayer}>
          <context.SearchProvider>
            <components.Search />
          </context.SearchProvider>

          <components.MapTitle title={constants.mapTitle} />

          {/* Mobile-only button next to Search */}
          <div className={styles.layerButtonMobile}>
            <dropdown.Dropdown {...dropdownProps} />
          </div>
          {/* Mobile-only Layer button will display dropdown contents under search/button */}
          <div className={styles.dropdownContentsContainer}>
            <dropdown.Body {...dropdownContentsProps} />
          </div>
        </div>

        <div className={styles.buttonBottomRight}>
          <components.Button {...buttonProps}>
            <QuestionMarkIcon />
          </components.Button>
        </div>

        <components.Mapbox />

        <components.MetricsPanel>
          {currentSelection.selectedLayer.product && (
            <components.PanelContents />
          )}
        </components.MetricsPanel>
      </div>

      <components.Modal {...modalProps}>
        {currentModal.modalContent}
      </components.Modal>
    </>
  );
}
