const apiPrefix = `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_API_VERSION}`;

export async function getStats(gid, product) {
  const response = await fetch(
    `${apiPrefix}/activity/data_categories?stac_collection_id=${product}&gid_value=${gid}`
  );
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    return;
  }
}

export async function getBaseline(gid, product) {
  const response = await fetch(
    `${apiPrefix}/activity/data_baseline?stac_collection_id=${product}&gid_value=${gid}`
  );
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    return;
  }
}

export async function getTooltip(gid, product) {
  const group_name = product.split("-")[2]; // e.g. "ctrees-deforestation-planet-biannual" -> "planet"
  const response = await fetch(
    `${apiPrefix}/activity/tooltip_agg?group_name=${group_name}&gid_value=${gid}`
  );
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    return;
  }
}

export async function getSpecialEvents() {
  const response = await fetch(`${apiPrefix}/activity/special_events`);
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    return;
  }
}
