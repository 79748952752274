import { useState, useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import clsx from "clsx";
import { useCurrentMap } from "../../../../contexts/MapProvider";

export default function Coordinates() {
  const { currentMap } = useCurrentMap();
  const map = currentMap.mapRef.current;
  const [lngLat, setLngLat] = useState({ lng: 0, lat: 0 });
  const popupRef = useRef(null);

  useEffect(() => {
    if (!map) return;

    // Resizes map so that the canvas fills the container
    // Resolves half-screen error on mobile.
    map.on("idle", function () {
      map.resize();
    });

    // Add contextmenu (right-click) listener
    map.on("contextmenu", function (e) {
      // Get the longitude and latitude of the right-click
      const clickedLngLat = e.lngLat;
      const coordinates = `Latitude, Longitude : ${clickedLngLat.lat.toFixed(
        5
      )}, ${clickedLngLat.lng.toFixed(5)}`;

      // Copy the coordinates to the clipboard
      navigator.clipboard
        .writeText(coordinates)
        .then(() => {
          // Remove existing popup if any
          if (popupRef.current) {
            popupRef.current.remove();
          }

          // Create a new popup at the clicked location
          const popup = new mapboxgl.Popup({
            closeOnClick: true,
            closeButton: false,
          })
            .setLngLat(clickedLngLat)
            .setHTML(`<div style="font-size: 12px;">Coordinates copied!</div>`)
            .addTo(map);

          popupRef.current = popup;

          // Remove the popup after 1 second
          setTimeout(() => {
            popup.remove();
            popupRef.current = null;
          }, 1000);
        })
        .catch((err) => {
          console.error("Failed to copy coordinates", err);
        });
    });

    map.on("mousemove", (e) => {
      setLngLat(e.lngLat);
    });

    return () => {
      map.off("contextmenu");
      map.off("mousemove");
    };
  }, [map]);

  return (
    <div className={coordinatesContainerStyle}>
      {lngLat.lat.toFixed(4)}, {lngLat.lng.toFixed(4)}
    </div>
  );
}

const coordinatesContainerStyle = clsx(
  "hidden lg:block absolute bottom-1 left-[240px] text-sm text-black bg-white/80 p-1 rounded-md"
);
