// Description: Constants for the REDD+AI platform
const dataLastUpdated = "16 Oct 2024";
const version = "v1.0";
export const mapTitle = `REDD+AI`;

// data ranges
const treeCoverLossRange = "2017 - 23";
const stableForestCoverRange = "2016 - 23";

// tileserver layers on map
export const treeCoverLossPlanet = "ctrees-deforestation-planet-mosaic";
export const loggingPlanet = "ctrees-logging-planet-mosaic";
export const firePlanet = "ctrees-fire-planet-mosaic";
export const roadsPlanet = "ctrees-roads-planet-mosaic";

// keys for activity types json data
const stableForestKey = "STABLE_FOREST";
const deforestedKey = "DEFORESTED";
const loggingKey = "DEGRADED_LOGGING";
const fireKey = "DEGRADED_FIRE";
const roadsKey = "DEGRADED_ROADS";

// display name for each activity type
export const activityTypes = {
  [deforestedKey]: "Tree Cover Loss",
  [stableForestKey]: "Stable Forest",
  [loggingKey]: "Degradation - Logging",
  [fireKey]: "Degradation - Fire",
  [roadsKey]: "Degradation - Roads",
};

// special colors
export const stableForestColor = "#167307";

// layer urls
const layerUrl = (layer, version = "v0") => {
  return `https://tileserver-activity.s3.us-west-2.amazonaws.com/${layer}/${version}/{z}/{x}/{y}.png`;
};

export const layerUrls = {
  [treeCoverLossPlanet]: layerUrl("planet_deforestation", "v2_final_20240918"),
  [loggingPlanet]: layerUrl("planet_logging", "v2_2024_10_08"),
  [firePlanet]: layerUrl("planet_fire", "v2_2024_10_08"),
  [roadsPlanet]: layerUrl("planet_road", "v2_road_2024_10_04"),
};

export const stableForestRaster = layerUrl(
  "planet_stable_forest",
  "v2_20241018"
);

// keys for aggregate summary json data
const value = "value";
const avg_deforestation_rate = "avg_deforestation_rate";

// titles of AggregateSummary components
export const forestCoverTitle = `Stable Forest (${stableForestCoverRange})`;
const deforestationRateTitle = (layer) => {
  return `Degradation - ${layer} Rate`;
};
const totalDeforestation = (layer) => {
  return `Total Degradation - ${layer}`;
};

// panel configurations
export const panelDeforestation = {
  layer: treeCoverLossPlanet,
  baselineStats: [
    {
      key: stableForestKey,
      title: forestCoverTitle,
      value: value,
    },
    { key: deforestedKey, title: "Total Tree Cover Loss", value: value },
    {
      key: deforestedKey,
      title: "Tree Cover Loss Rate",
      value: avg_deforestation_rate,
    },
  ],
  infoTooltip: {
    title: "Tree Cover Loss",
    content: [
      `<p><span style="background-color:#ffffff;font-size:11pt;"><b>Tree Cover Loss</b> measures forested areas that have undergone a complete removal of trees. The model detects tree cover loss when classification of a pixel changes from tree cover to non-tree cover, and remains classified as non-tree cover. The Tree Cover Loss layer also displays stable forest cover, which are pixels classified as tree cover over the entire period covered by the Planet NICFI dataset (2016 to present). <br/> <br/> <b>Stable Forest (${stableForestCoverRange}):</b> The <i>Stable Forest</i> layer measures any area that remained forest during the 2016-current period analyzed by CTrees. <br/> <br/> <b>Rate:</b> The rate measures the total area of disturbance divided by the number of years selected by the user. </span></p>`,
    ],
  },
};

export const panelLogging = {
  layer: loggingPlanet,
  baselineStats: [
    { key: loggingKey, title: totalDeforestation("Logging"), value: value },
    {
      key: loggingKey,
      title: deforestationRateTitle("Logging"),
      value: avg_deforestation_rate,
    },
  ],
  infoTooltip: {
    title: "Degradation - Logging",
    content: [
      '<p><span style="background-color:#ffffff;font-size:11pt;"> <b>Degradation:</b> Degradation in tropical rainforests consists of losses of tree cover and carbon storage, among other ecosystem services, which does not result in complete clearing of the forest. This tool measures degradation caused by logging, fire, and road construction. <br/> <br/> <b>Degradation - Logging:</b> The Degradation - Logging layer measures forest degradation due to logging. In the tropics, logging includes selective logging, where trees are removed inside  forests without clear cutting, over logging cycles that can last decades. Logged forests are expected to regenerate over time without additional tree removal. The Degradation — Logging layer encompasses both legal and illegal logging. Logging can be legal in forest concessions where companies are required to follow rules of sustainable forest management. Illegal logging can result in severely increased levels of disturbance. <br/> <br/>  <b>Rate:</b> The rate measures the total area of disturbance divided by the number of years selected by the user.</span></p>',
    ],
  },
};

export const panelFire = {
  layer: firePlanet,
  baselineStats: [
    { key: fireKey, title: totalDeforestation("Fire"), value: value },
    {
      key: fireKey,
      title: deforestationRateTitle("Fire"),
      value: avg_deforestation_rate,
    },
  ],
  infoTooltip: {
    title: "Degradation - Fire",
    content: [
      '<p><span style="background-color:#ffffff;font-size:11pt;"><b>Degradation:</b> Degradation in tropical rainforests consists of losses of tree cover and carbon storage, among other ecosystem services, which does not result in complete clearing of the forest. This tool measures degradation caused by logging, fire, and road construction. <br/> <br/> <b>Degradation - Forest Fire:</b> The Degradation — Forest Fire layer measures area of burned forests, where the forest has been partially lost or its ecosystem services diminished. Areas subject to forest fire degradation often experience increased tree mortality and reduced productivity that can last from years to decades.<br/> <br/> <b>Rate:</b> The rate measures the total area of disturbance divided by the number of years selected by the user.</span></p>',
    ],
  },
};

export const panelRoads = {
  layer: roadsPlanet,
  baselineStats: [
    { key: roadsKey, title: totalDeforestation("Road"), value: value },
    {
      key: roadsKey,
      title: deforestationRateTitle("Road"),
      value: avg_deforestation_rate,
    },
  ],
  infoTooltip: {
    title: "Degradation - Roads",
    content: [
      '<p><span style="background-color:#ffffff;font-size:11pt;"><b>Degradation:</b> Degradation in tropical rainforests consists of losses of tree cover and carbon storage, among other ecosystem services, which does not result in complete clearing of the forest. This tool measures degradation caused by logging, fire, and road construction. <br/><br/> <b>Degradation - Roads:</b> The Degradation —  Roads layer measures area degraded by construction of roads and trails inside forests, which can be used for transportation or for illegal activities. Roads are often connected to new deforestation and logging hotspots. <br/> <br/> <b>Rate:</b> The rate measures the total area of disturbance divided by the number of years selected by the user. </span></p>',
    ],
  },
};

export const panelConfig = {
  [treeCoverLossPlanet]: panelDeforestation,
  [loggingPlanet]: panelLogging,
  [firePlanet]: panelFire,
  [roadsPlanet]: panelRoads,
};

// layer dropdown options
const res5m = "(2017-2023 | 5 m)";
export const layerOptions = [
  {
    displayName: "Degradation - Logging",
    info: res5m,
    product: loggingPlanet,
    subjects: [loggingKey],
    serverCall: layerUrls[loggingPlanet],
  },
  {
    displayName: "Degradation - Fire",
    info: res5m,
    product: firePlanet,
    subjects: [fireKey],
    serverCall: layerUrls[firePlanet],
  },
  {
    displayName: "Degradation - Roads",
    info: res5m,
    product: roadsPlanet,
    subjects: [roadsKey],
    serverCall: layerUrls[roadsPlanet],
  },
  {
    displayName: "Tree Cover Loss",
    info: res5m,
    product: treeCoverLossPlanet,
    subjects: [deforestedKey, stableForestKey],
    serverCall: layerUrls[treeCoverLossPlanet],
    stableForestToggle: true,
  },
];

// modal configurations
export const questionMarkModal = {
  title: "Methodology",
  contents: [
    ` <div className='flex flex-col space-y-2 h-48 overflow-y-auto p-6 m-6'>
   <p> The REDD+AI platform provides open access for visualization of CTrees’ maps of stable forest cover, tree cover loss, and forest degradation, with attribution to logging, forest fire, and road construction. Data available cover the tropical moist forests biome at 4.77-meter resolution for 2017-2023. The platform offers summary statistics for all countries and their subnational jurisdictions where data are available.  
<br/>
<br/>
The 4.77-meter data with attributions correspond to CTrees maps of tree cover loss and degradation based on images from the PlanetScope satellite constellation, provided by Planet Labs PBC and made available via Norway’s International Climate and Forests Initiative (NICFI). To produce the data, CTrees trained and applied AI models to the satellite images, as described in peer-reviewed publications [1][2]. The dataset covers the period of 2017 to present, at biannual to monthly time scales.
<br/>
<br/>
The datasets offer unprecedented spatial and temporal resolution. CTrees produces degradation layers data bi-annually (twice per year) from 2016 to present, and tree cover loss bi-annually (twice per year) from 2017 to June 2020, and monthly from September 2020 to present. At present, the REDD+AI platform displays data at annual time scales. 2024 data will be released in the next version.
<br/>
<br/>
These data are the first pantropical dataset to attribute areas of forest degradation to their specific drivers: logging, forest fire, and road construction. Pixels experiencing complete tree cover removal were defined as loss, and areas affected by disturbances from logging (including both legal or illegal logging), forest fire, or road construction were mapped separately and can depict potentially degraded forests. An Algorithm Theoretical Basis Documents (ATBD) provides additional detail [3].
<br/>
<br/>
The frequency of the Planet NICFI data changed in September 2020 from biannual to monthly. We can expect data to spike in 2020 as more tree cover loss events are picked up. For further limitations, please reference the Algorithm Theoretical Basis Documents (ATBD).
<br/>
<br/>
<b>References</b>
<br/>
[1] Wagner, Fabien H., et al. 2023. "Mapping Tropical Forest Cover and Deforestation with Planet NICFI Satellite Images and Deep Learning in Mato Grosso State (Brazil) from 2015 to 2021" Remote Sensing 15, no. 2: 521. https://doi.org/10.3390/rs15020521
<br/>
[2] Dalagnol, R. et al. 2023. Mapping tropical forest degradation with deep learning and Planet NICFI data” *Remote Sensing of Environment*, Vol. 298 https://doi.org/10.1016/j.rse.2023.113798
<br/>
[3] <a class="underline underline-offset-4 hover:text-gray clicked:text-yellowButton" href="https://ctrees-website.s3.us-west-2.amazonaws.com/product/REDD%2BAI+ATBD.pdf" target="_blank" rel="noopener noreferrer">REDD+AI DOCUMENTATION ${version}</a>
<br/>
<br/>
<i>Data last updated ${dataLastUpdated}</i></p> </div>`,
  ],
};

// map constants
export const maxZoom = 14;
export const minZoom = 3;

export const mapStyle = {
  gidOnLoad: "BRA",
  center: [-51.92528, -14.235004], // BRA (longitude, latitude)
  style: "/alidade_satellite.json",
  maxZoom: maxZoom,
  minZoom: minZoom,
  tileserverOnLoad: layerOptions[0].serverCall,
  outlineColor: "#dff840", // selected outline color
  fill: true, // selected fill
  borderColor: "white", // border color of region children when selected
};
